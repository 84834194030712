.sidebarBody {
  width: 15rem;
  position: fixed;
  height: 100vh;
  background-color: #d3a938;
  padding: 1rem !important;
}
.sidebarBodyMobile {
  background-color: #d3a938 !important;
  padding: 1rem !important;
}
.forActive li a:hover {
  color: #a66e2c !important;
  text-decoration: underline;
  cursor: pointer;
}
/* .forActive li a:active {
  color: #cc963e !important;
} */
ol li {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  padding: 0.7rem;
  margin-left: 0px;
}

ol li a {
  color: white;
  text-decoration: none;
}
ol {
  padding-left: 0px !important;
}
.iconStyle {
  margin-right: 8px;
}
.active {
  color: #9e7a50 !important;
}
.bottomDiv {
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.item {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  padding: 0.7rem;
  background-color: transparent;
  border: none;
  text-decoration: none;
}
.innerlogo {
  width: 24px;
  height: 24px;
  margin-right: 5px !important;
  margin-left: -3px;
}
/*Dashboard*/
.bodyDashboard {
  padding: 2.5rem;
  margin-left: 15rem;
  margin-right: 21rem;
}
.text {
  font-size: x-large;
  font-weight: 600;
  color: #000;
}
.innerdivSection {
  background-color: #ececec;
  padding: 1rem;
  border-radius: 0.75rem;
}
.valuesFirst {
  font-weight: bolder;
  font-size: 22px;
}
.TaxCalculator::placeholder {
  font-size: 22px;
}

/* Announcment */
.sectionTwitter {
  font-size: 17px;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  padding: 0.5rem;
}

/* RightSidebar */
.rightSidebarbody {
  width: 21rem;
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  border-left: 2px solid gray;
}
.forHover:hover {
  background-color: #cab478 !important;
  color: white !important;
  border-radius: 15px;
}
.connect {
  background-color: #e7d618;
  font-weight: 600;
  border: none;
  padding: 9px 11px;
  border-radius: 15px;
  display: flex;
  margin: auto;
  color: white;
  font-weight: 700;
}
.connect:hover {
  padding: 11px 13px;
}
.accordion-item {
  border: none !important;
}
.accordion-button {
  font-weight: 700;
}
.disabled {
  cursor: not-allowed !important;
}
.mywalletMain {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}
.mywalletMaininner {
  box-sizing: border-box;
  display: block;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  max-width: 100%;
}
.myWalletDiv2 {
  visibility: hidden;
  position: absolute;
  min-width: max-content;
  inset: 0px auto auto 0px;
}

.mywalletDivImage {
  width: 80px;
  height: 40px;
}
.mywalletDiv {
  display: block;
  max-width: 100%;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
}
.walletBody {
  background-color: #d3a938;
  border-radius: 0.5rem;
}
.moreIcon {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
}
.popButton {
  background-color: transparent;
  border: none;
  color: white;
  padding: 5px;
}
.mobileNavBody {
  display: none;
}
.forDesktopView {
}
.accordion-button:not(.collapsed) {
  color: #000 !important;
}
@media screen and (max-width: 680px) {
  .rightSidebarbody {
    display: none;
  }
  .sidebarBody {
    display: none;
  }
  .bodyDashboard {
    margin-left: 0px;
    margin-right: 0px;
  }
  .forMobile {
    flex-direction: column;
  }
  .mobileNavBody {
    background-color: #d3a938;
    height: 5rem;
    display: block;
  }
  .bgmobile {
    background-color: transparent;
    border: none;
    border: 1px solid gray;
    margin-top: 20px;
  }
  .connect {
    display: unset;
    margin: unset;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
      var(--bs-accordion-border-color);
  }
  .forMobileView {
    display: none;
  }
}
.accordion-button:not(.collapsed) {
  background-color: white !important;
  border: none !important;
  /* box-shadow: none !important; */
}
